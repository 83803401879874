import { Label, SelectPrimary } from '@gathercontent/ui';
import { useContext } from 'react';
import type { SelectHTMLAttributes } from 'react';

import { FormContext } from '~/components/.shared/form/formContext';
import { ErrorTextList } from '~/components/.ui/errorHandling/ErrorTextList';

interface FormInputFieldProps extends SelectHTMLAttributes<HTMLSelectElement> {
  label: string;
  name: string;
  wrapperClasses?: string;
  errorLocation?: 'top' | 'bottom';
}

export function FormSelectField({
  label,
  name,
  children,
  id,
  wrapperClasses = 'mb-6',
  errorLocation = 'bottom',
  ...props
}: FormInputFieldProps) {
  const { fieldErrors, defaultValues, validateField } = useContext(FormContext);
  const errors = fieldErrors && fieldErrors[name];
  const errorTextId = `${name}-errors`;

  return (
    <div className={wrapperClasses}>
      <div className="flex items-center justify-between">
        <Label htmlFor={name}>{label}</Label>
        {errors && errorLocation === 'top' && <ErrorTextList id={errorTextId} messages={errors} className="m-0 pb-2" />}
      </div>
      <SelectPrimary
        onChange={(e) => validateField(name, e.target.value)}
        name={name}
        id={id}
        defaultValue={defaultValues && defaultValues[name]}
        aria-describedby={errors ? errorTextId : ''}
        invalid={Boolean(errors)}
        {...props}
      >
        {children}
      </SelectPrimary>

      {errors && errorLocation === 'bottom' && <ErrorTextList id={errorTextId} messages={errors} />}
    </div>
  );
}
