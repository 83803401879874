import { Label, TextArea } from '@gathercontent/ui';
import { useContext } from 'react';
import type { TextareaHTMLAttributes } from 'react';

import { FormContext } from '~/components/.shared/form/formContext';
import { ErrorTextList } from '~/components/.ui/errorHandling/ErrorTextList';

export interface FormTextareFieldProps extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  label?: string;
  name: string;
  wrapperClassName?: string;
  inputWrapperClassName?: string;
  validateEvents?: string[];
  inputRef?: React.MutableRefObject<HTMLTextAreaElement>;
  errorLocation?: 'top' | 'bottom';
}

export function FormTextareaField({
  label,
  name,
  wrapperClassName = '',
  inputWrapperClassName = '',
  children,
  validateEvents = ['onBlur'],
  id = '',
  inputRef,
  rows = 4,
  errorLocation = 'bottom',
  ...props
}: FormTextareFieldProps) {
  const { fieldErrors, defaultValues, validateField } = useContext(FormContext);
  const errors = fieldErrors && fieldErrors[name];
  const errorTextId = `${name}-errors`;

  return (
    <div className={`mb-6 relative ${wrapperClassName}`}>
      <div className="flex items-center justify-between">
        {label && <Label htmlFor={name}>{label}</Label>}
        {errors && errorLocation === 'top' && <ErrorTextList messages={errors} id={errorTextId} className="m-0 pb-2" />}
      </div>
      <TextArea
        onBlur={(e) => {
          if (validateEvents.includes('onBlur')) {
            validateField(name, e.target.value);
          }
        }}
        onChange={(e) => {
          if (validateEvents.includes('onChange')) {
            validateField(name, e.target.value);
          }
        }}
        name={name}
        id={id || name}
        defaultValue={defaultValues && defaultValues[name]}
        aria-describedby={errors ? errorTextId : ''}
        invalid={Boolean(errors)}
        inputRef={inputRef}
        rows={rows}
        {...props}
      />
      {errors && errorLocation === 'bottom' && <ErrorTextList messages={errors} id={errorTextId} />}
    </div>
  );
}
