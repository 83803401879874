import { LexicalComposer } from '@lexical/react/LexicalComposer';
import { ContentEditable } from '@lexical/react/LexicalContentEditable';
import LexicalErrorBoundary from '@lexical/react/LexicalErrorBoundary';
import { HistoryPlugin } from '@lexical/react/LexicalHistoryPlugin';
import { OnChangePlugin } from '@lexical/react/LexicalOnChangePlugin';
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin';
import type { EditorState, LexicalEditor } from 'lexical';

interface Props {
  placeholderText?: string;
  onChange: (editorState: EditorState, editor: LexicalEditor) => void;
  invalid?: boolean;
}

export function LexicalTextBox({ placeholderText, onChange, invalid }: Props) {
  const innerClassNames = 'relative pb-[5px]';
  const inputClassNames = `relative h-28 px-3 py-2 resize-none text-base overflow-y-scroll outline-0 text-input hover:border-blue-primary focus:border-transparent focus:shadow-input-focus ${invalid ? 'text-input-invalid' : ''}`;
  const placeholderClassNames =
    'absolute overflow-hidden top-2 left-3 text-base text-neutral-primary select-none inline-block pointer-events-none';

  const initialConfig = {
    namespace: 'richText',
    onError(error: unknown) {
      throw error;
    },
  };

  return (
    <LexicalComposer initialConfig={initialConfig}>
      <div className={innerClassNames}>
        <RichTextPlugin
          contentEditable={<ContentEditable className={inputClassNames} />}
          placeholder={<div className={placeholderClassNames}>{placeholderText}</div>}
          ErrorBoundary={LexicalErrorBoundary}
        />
        <OnChangePlugin onChange={onChange} />
        <HistoryPlugin />
      </div>
    </LexicalComposer>
  );
}
