const passwordHasLength = '(?=^.{8,64}$)';
const passwordHasLowercase = '(?=.*[a-z])';
const passwordHasUppercase = '(?=.*[A-Z])';
const passwordHasNumber = '(?=.*\\d)';
const passwordHasSpecial = '(?=.*[\\W_])';

export const passwordLengthRegex = new RegExp(passwordHasLength);
export const passwordLowercaseRegex = new RegExp(passwordHasLowercase);
export const passwordUppercaseRegex = new RegExp(passwordHasUppercase);
export const passwordNumberRegex = new RegExp(passwordHasNumber);
export const passwordSpecialRegex = new RegExp(passwordHasSpecial);
