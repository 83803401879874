import { LoaderIcon, TrashIcon } from '@gathercontent/icons';
import { ButtonIcon } from '@gathercontent/ui';
import { useFetcher, useLocation } from '@remix-run/react';
import { useTypedLoaderData as useLoaderData } from 'remix-typedjson';

import type { loader } from '~/routes/project.$projectId.item.$itemId.field.$fieldId.bynder.dat';

import { Form } from '~/components/.shared/form/Form';

type ExistingTransformationSummaryProps = {
  value: string;
  displayValue?: string;
  icon: React.ElementType;
  className?: string;
};

export function ExistingTransformationSummary({
  value,
  icon,
  className = 'mr-4 pr-3',
  displayValue,
}: ExistingTransformationSummaryProps) {
  const { src, transformationParams, urlWithNoTransforms, instanceId, fieldId } = useLoaderData<typeof loader>();
  const fetcher = useFetcher();
  const location = useLocation();

  const Icon = icon;
  return (
    <div className={`w-full flex items-center ${className}`}>
      <Icon className="fill-neutral-20" />
      <span className="capitalize ml-2">{displayValue || value}</span>
      <Form
        FetcherForm={fetcher.Form}
        action={`${location.pathname}${location.search}`}
        method="post"
        className="ml-auto"
      >
        <input type="hidden" value={transformationParams} name="transformationParams" />
        <input type="hidden" value={urlWithNoTransforms} name="urlWithNoTransforms" />
        <input type="hidden" value={src || ''} name="src" />
        <input type="hidden" value={instanceId || ''} name="instanceId" />
        <input type="hidden" value={fieldId} name="fieldId" />
        <ButtonIcon type="submit" name="_remove" value={value}>
          {fetcher.state !== 'idle' ? <LoaderIcon /> : <TrashIcon className="pointer-events-none fill-red-primary" />}
        </ButtonIcon>
      </Form>
    </div>
  );
}
