import { Box } from '@gathercontent/ui';
import type { Error } from 'ts-json-api';

import { passwordErrorMessages as requirements } from '~/data/user/.schema/passwordSchema';

import { PasswordChecklistRequirement } from './PasswordChecklistRequirement';

type ChecklistProps = {
  errors: string[] | undefined | Error;
  fieldHasValue: boolean;
};

export function PasswordChecklist({ errors, fieldHasValue }: ChecklistProps) {
  if (!Array.isArray(errors)) return null;
  const optionalRequirements = Object.keys(requirements).filter((requirement) => requirement !== 'length');

  return (
    <Box paddingSize="md" type="float" className="absolute z-10 right-0 mt-1" aria-hidden>
      <h4>Requires</h4>
      <PasswordChecklistRequirement errors={errors} requirement={requirements.length} fieldHasValue={fieldHasValue} />

      <h4 className="mt-4">And 3 of the following</h4>
      {optionalRequirements.map((optional) => (
        <PasswordChecklistRequirement
          key={optional}
          errors={errors}
          requirement={requirements[optional as keyof typeof requirements]}
          fieldHasValue={fieldHasValue}
        />
      ))}
    </Box>
  );
}
