import type { Error } from 'ts-json-api';

import { useHydrated } from '~/helpers/hooks/useHydrated';

import { ErrorTextList } from '~/components/.ui/errorHandling/ErrorTextList';

import { PasswordChecklist } from './checklist/PasswordChecklist';

type ValidationProps = {
  errors: string[] | undefined | Error;
  showChecklist: boolean;
  fieldHasValue: boolean;
  enablePasswordChecklist: boolean;
  errorTextId: string;
};

export function PasswordFieldValidation({
  errors,
  fieldHasValue,
  showChecklist,
  enablePasswordChecklist,
  errorTextId,
}: ValidationProps) {
  const hydrated = useHydrated();

  return (
    <>
      {hydrated && showChecklist && enablePasswordChecklist && (
        <PasswordChecklist errors={errors} fieldHasValue={fieldHasValue} />
      )}

      {errors && <ErrorTextList messages={['Password requirements not met.']} id={errorTextId} />}

      {errors && <ErrorTextList messages={errors} id={errorTextId} className="sr-only" />}
    </>
  );
}
