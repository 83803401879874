import { useContext, useEffect, useRef, useState } from 'react';

import { useEnv } from '~/data/env/hooks/useEnv';

import { FormContext } from '~/components/.shared/form/formContext';
import { ErrorTextList } from '~/components/.ui/errorHandling/ErrorTextList';
import type { Window } from '~/components/root/.types/globalTypes';

export function FormCaptcha() {
  const env = useEnv();
  const { fieldErrors, validateField } = useContext(FormContext);
  const name = 'h-captcha-response';
  const errors = fieldErrors && fieldErrors[name];

  const captchaRef = useRef<HTMLDivElement>(null);
  const hasMounted = useRef(false);
  const captchaRendered = useRef(false);

  const [token, setToken] = useState('');

  const errorTextId = `${name}-errors`;

  useEffect(() => {
    const hcaptcha = (window as Window).hcaptcha;
    const hcaptchaRefReady = captchaRef.current && !captchaRendered.current;
    const hcaptchaScriptReady = hcaptcha && hcaptcha.render && typeof hcaptcha.render === 'function';

    if (hcaptchaRefReady && hcaptchaScriptReady) {
      hcaptcha.render(captchaRef.current, {
        callback: (token: string) => setToken(token),
        'expired-callback': () => setToken(''),
      });
      captchaRendered.current = true;
    }
  }, [captchaRef.current]);

  useEffect(() => {
    if (hasMounted.current) {
      validateField(name, token);
    } else {
      hasMounted.current = true;
    }
  }, [token]);

  return (
    <div className="mb-6">
      <div
        aria-describedby={errors ? errorTextId : ''}
        className="h-captcha mt-6 flex justify-center"
        data-sitekey={env?.HCAPTCHA_SITE_KEY}
        ref={captchaRef}
      />
      {errors && <ErrorTextList className="text-center" messages={errors} id={errorTextId} />}
    </div>
  );
}
