import { useFetcher, useLocation } from '@remix-run/react';
import { createContext, useRef } from 'react';
import { useTypedLoaderData as useLoaderData } from 'remix-typedjson';
import type { ZodSchema } from 'zod';

import type { loader } from '~/routes/project.$projectId.item.$itemId.field.$fieldId.bynder.dat';

import { useHydrated } from '~/helpers/hooks/useHydrated';

import { Form } from '~/components/.shared/form/Form';
import { FormSubmitButton } from '~/components/.shared/form/FormSubmitButton';

import { ExistingTransformationAccordion } from './ExistingTransformationAccordion';
import { ExistingTransformationSummary } from './ExistingTransformationSummary';

type ExistingTransformationFormProps<SchemaType> = {
  value: string;
  displayValue?: string;
  icon: React.ElementType;
  buttonText?: string;
  children?: React.ReactNode;
  schema?: ZodSchema<SchemaType>;
};

export const TransformationFormContext = createContext({
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  submitForm: () => {},
});

export function ExistingTransformationForm<SchemaType>({
  children,
  value,
  icon,
  buttonText,
  schema,
  displayValue,
}: ExistingTransformationFormProps<SchemaType>) {
  const { src, transformationParams, urlWithNoTransforms, instanceId, fieldId } = useLoaderData<typeof loader>();
  const fetcher = useFetcher();
  const hydrated = useHydrated();
  const location = useLocation();
  const formRef = useRef<HTMLFormElement | null>(null);

  const autoSubmit = () => {
    if (formRef.current && hydrated) {
      fetcher.submit(formRef.current);
    }
  };

  const contextValue = {
    submitForm: autoSubmit,
  };

  return (
    <div className="bg-white border-b border-solid border-neutral-90">
      {children ? (
        <ExistingTransformationAccordion displayValue={displayValue} value={value} icon={icon}>
          <TransformationFormContext.Provider value={contextValue}>
            <Form
              FetcherForm={fetcher.Form}
              action={`${location.pathname}${location.search}`}
              method="post"
              schema={schema}
              formRef={formRef}
              onChange={() => autoSubmit()}
            >
              <input type="hidden" value={transformationParams} name="transformationParams" />
              <input type="hidden" value={urlWithNoTransforms} name="urlWithNoTransforms" />
              <input type="hidden" value={src || ''} name="src" />
              <input type="hidden" value={fieldId} name="fieldId" />
              <input type="hidden" value={instanceId || ''} name="instanceId" />
              {hydrated && <input type="hidden" value={value} name="_add" />}
              {children}
              {buttonText && !hydrated && (
                <FormSubmitButton loading={fetcher.state !== 'idle'} disabled={fetcher.state !== 'idle'} value={value}>
                  {buttonText}
                </FormSubmitButton>
              )}
            </Form>
          </TransformationFormContext.Provider>
        </ExistingTransformationAccordion>
      ) : (
        <ExistingTransformationSummary
          displayValue={displayValue}
          icon={icon}
          value={value}
          className="accordion-summary cursor-default"
        />
      )}
    </div>
  );
}
