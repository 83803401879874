import { Label, Slider, TextInput } from '@gathercontent/ui';
import { useContext, useEffect, useState } from 'react';
import type { HTMLAttributes } from 'react';

import { useHydrated } from '~/helpers/hooks/useHydrated';

import { FormContext } from '~/components/.shared/form/formContext';
import { ErrorTextList } from '~/components/.ui/errorHandling/ErrorTextList';
import { TransformationFormContext } from '~/components/bynder/dat/existingTransformations/existingTransformationForm/ExistingTransformationForm';

interface Props extends HTMLAttributes<HTMLDivElement> {
  value: number;
  name: string;
  min?: number;
  max?: number;
  step?: number;
  units?: string;
  label: string;
  showTextInput?: boolean;
}

export function FormSliderField({
  value,
  name,
  min = 1,
  max = 100,
  step = 1,
  units = '',
  label,
  showTextInput = true,
}: Props) {
  const [inputValue, setInputValue] = useState(value);
  const hydrated = useHydrated();
  const { fieldErrors, defaultValues, validateField } = useContext(FormContext);
  const { submitForm } = useContext(TransformationFormContext);
  const errors = fieldErrors && fieldErrors[name];
  const errorTextId = `${name}-errors`;

  const textInputClassName = `${hydrated ? 'mx-3 w-1/4' : 'mr-3'}`;

  useEffect(() => {
    submitForm();
  }, [inputValue]);

  return (
    <div>
      <Label htmlFor={name} id={`${name}-label`} className="pb-0">
        {label}
      </Label>
      <div className="flex items-center justify-center">
        {(!showTextInput || hydrated) && (
          <Slider
            defaultValue={defaultValues && defaultValues[name]}
            value={+inputValue}
            onChange={(e) => {
              setInputValue(+e.target.value);
              validateField(name, e.target.value);
            }}
            min={min}
            max={max}
            step={step}
            aria-labelledby={`${name}-label`}
            name={name}
          />
        )}
        {showTextInput && (
          <TextInput
            id={name}
            onFocus={(e) => (e.target.name = name)}
            value={+inputValue}
            onChange={(e) => {
              setInputValue(+e.target.value);
              validateField(name, e.target.value);
            }}
            className={textInputClassName}
            aria-labelledby={`${name}-label`}
            type="number"
            inputMode="numeric"
            min={min}
            max={max}
          />
        )}
        {units && <p className="mb-0">{units}</p>}
      </div>
      {errors && <ErrorTextList messages={errors} id={errorTextId} />}
    </div>
  );
}
