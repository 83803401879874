import { Form as RemixForm } from '@remix-run/react';
import type { FormProps as RemixFormProps } from '@remix-run/react';
import { ElementType, useMemo, useState } from 'react';
import type { ReactNode } from 'react';
import type { ZodSchema } from 'zod';

import { DataFunctionResult } from '~/data/.types/dataFunctionTypes';

import { CsrfTokenInput } from '~/helpers/auth/csrf/CsrfTokenInput';

import type { FieldErrors } from '~/components/.shared/form/.types/formTypes';
import { FormCaptcha } from '~/components/.shared/form/FormCaptcha';
import { FormError } from '~/components/.shared/form/FormError';
import { FormFieldset } from '~/components/.shared/form/FormFieldset';
import { FormHoneypotField } from '~/components/.shared/form/FormHoneypotField';
import { FormInputField } from '~/components/.shared/form/FormInputField';
import { FormSelectField } from '~/components/.shared/form/FormSelectField';
import { FormSliderField } from '~/components/.shared/form/FormSliderField';
import { FormSubmitButton } from '~/components/.shared/form/FormSubmitButton';
import { FormTextareaField } from '~/components/.shared/form/FormTextareaField';
import { FormTimestampField } from '~/components/.shared/form/FormTimestampField';
import { FormContext, FormContextValue } from '~/components/.shared/form/formContext';
import { FormPasswordField } from '~/components/.shared/form/password/FormPasswordField';
import { FormRichTextField } from '~/components/.shared/form/rich-text/FormRichTextField';
import { useDefaultValues } from '~/components/.shared/form/useDefaultValues';
import { useFieldErrors } from '~/components/.shared/form/useFieldErrors';

interface FormProps<SchemaType> extends RemixFormProps {
  FetcherForm?: ElementType;
  fetcherData?: DataFunctionResult<unknown> | undefined;
  children: ReactNode;
  schema?: ZodSchema<SchemaType>;
  autoSubmit?: boolean;
  formRef?: React.MutableRefObject<HTMLFormElement | null>;
}

export function Form<SchemaType>({
  FetcherForm,
  fetcherData,
  children,
  schema,
  autoSubmit = false,
  formRef,
  reloadDocument = false,
  ...props
}: FormProps<SchemaType>) {
  const [fieldErrors, setFieldErrors] = useFieldErrors(fetcherData);
  const [defaultValues, setDefaultValues] = useDefaultValues(fetcherData);
  const [fieldValues, setFieldValues] = useState({});

  const contextValue = useMemo<FormContextValue>(
    () => ({
      fieldErrors,
      fieldValues,
      defaultValues,
      reloadDocument,
      validateField: async (name, value) => {
        setFieldValues((existingValues) => {
          return {
            ...existingValues,
            [name]: value,
          };
        });
        if (schema) {
          const result = await schema.safeParseAsync({
            [name]: value,
          });

          if (!result.success) {
            const error = result.error.flatten().fieldErrors[name as keyof FieldErrors];
            setFieldErrors((errors) => ({
              ...errors,
              [name]: error,
            }));
          }

          if (result.success) {
            setFieldErrors({});
          }
        }
      },
    }),
    [schema, fieldErrors, setFieldErrors, defaultValues, setDefaultValues, fieldValues, setFieldValues, autoSubmit],
  );

  const FormComponent = FetcherForm || RemixForm;

  return (
    <FormContext.Provider value={contextValue}>
      <FormComponent {...props} reloadDocument={reloadDocument} ref={formRef}>
        {props.method !== 'get' && <CsrfTokenInput />}
        {children}
        <FormError fetcherData={fetcherData} />
      </FormComponent>
    </FormContext.Provider>
  );
}

Form.Fieldset = FormFieldset;
Form.InputField = FormInputField;
Form.PasswordField = FormPasswordField;
Form.SelectField = FormSelectField;
Form.SubmitButton = FormSubmitButton;
Form.HoneypotField = FormHoneypotField;
Form.TimestampField = FormTimestampField;
Form.SliderField = FormSliderField;
Form.Captcha = FormCaptcha;
Form.Error = FormError;
Form.RichTextField = FormRichTextField;
Form.TextareaField = FormTextareaField;
