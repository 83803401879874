import { useNavigation } from '@remix-run/react';
import { ReactNode } from 'react';

interface Props {
  children: ReactNode;
}

export function FormFieldset({ children, ...props }: Props) {
  const transition = useNavigation();

  return (
    <fieldset disabled={transition.state === 'submitting'} {...props}>
      {children}
    </fieldset>
  );
}
