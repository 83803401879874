import React, { useEffect, useState } from 'react';
import { useTypedActionData as useActionData } from 'remix-typedjson';

import type { DataFunctionResult } from '~/data/.types/dataFunctionTypes';

import type { FieldErrors } from '~/components/.shared/form/.types/formTypes';

export function useFieldErrors(
  fetcherData: DataFunctionResult<unknown> | undefined,
): [FieldErrors, React.Dispatch<React.SetStateAction<FieldErrors>>] {
  const actionData = useActionData<DataFunctionResult<{}>>();
  const errors = actionData?.error?.messages || fetcherData?.error?.messages || fetcherData?.error?.errors;
  const [fieldErrors, setFieldErrors] = useState<FieldErrors>(errors);

  useEffect(() => {
    setFieldErrors(errors);
  }, [errors]);

  return [fieldErrors, setFieldErrors];
}
