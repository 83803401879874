import { ButtonPrimary } from '@gathercontent/ui';
import { useNavigation } from '@remix-run/react';
import { useContext, useState } from 'react';
import type { ButtonHTMLAttributes, ReactNode } from 'react';

import { FormContext } from '~/components/.shared/form/formContext';

interface Props extends ButtonHTMLAttributes<HTMLButtonElement> {
  children: ReactNode;
  className?: string;
  value?: string;
  loading?: boolean;
  disabled?: boolean;
  disableWhenNotIdle?: boolean;
  success?: boolean;
}

export function FormSubmitButton({
  className,
  value,
  children,
  loading,
  disabled,
  disableWhenNotIdle = true,
  onClick,
  ...rest
}: Props) {
  const transition = useNavigation();
  const { reloadDocument } = useContext(FormContext);
  const [isLoading, setIsLoading] = useState(false);

  const handleOnClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (reloadDocument) {
      setIsLoading(true);
    }
    if (onClick) {
      onClick(e);
    }
  };

  return (
    <ButtonPrimary
      className={className}
      type="submit"
      name="_action"
      role="button"
      value={value}
      loading={loading || transition.state === 'submitting' || isLoading}
      disabled={disabled || (disableWhenNotIdle && transition.state !== 'idle')}
      onClick={handleOnClick}
      {...rest}
    >
      {children}
    </ButtonPrimary>
  );
}
