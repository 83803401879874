import { InputValidationText } from '@gathercontent/ui';
import { useTypedActionData as useActionData } from 'remix-typedjson';

import type { DataFunctionResult } from '~/data/.types/dataFunctionTypes';

type FormErrorProps = {
  fetcherData: DataFunctionResult<unknown> | undefined;
  action?: string | undefined;
};

export function FormError({ fetcherData, action }: FormErrorProps) {
  const actionData = useActionData<DataFunctionResult<{}>>();
  const errorStatus = actionData?.error?.status || fetcherData?.error?.status;

  if (
    (!Boolean(actionData) && !Boolean(fetcherData)) ||
    actionData?.data ||
    !Boolean(errorStatus) ||
    errorStatus === 422 ||
    actionData?.error?.values?._action !== action
  ) {
    return null;
  }

  switch (errorStatus) {
    case 429:
      return (
        <InputValidationText id="form-error" className="mt-2">
          Oops! Looks like you tried to request a page too many times.
        </InputValidationText>
      );
    default:
      return (
        <InputValidationText id="form-error" className="mt-2">
          Oops, something went wrong.
        </InputValidationText>
      );
  }
}
