import { CircleTickIcon } from '@gathercontent/icons';
import cx from 'classnames';

type RequirementProps = {
  errors: string[] | undefined;
  requirement: string;
  fieldHasValue: boolean;
};

export function PasswordChecklistRequirement({ errors, requirement, fieldHasValue }: RequirementProps) {
  const requirementPassed = (!errors && fieldHasValue) || (!!errors && !errors.some((error) => error === requirement));
  const iconClasses = cx('mr-3', {
    'fill-green-primary': requirementPassed,
    'fill-neutral-primary': !requirementPassed,
  });

  const spanClasses = cx('flex items-center my-2', {
    'text-neutral-70 line-through': requirementPassed,
    'text-neutral-20': !requirementPassed,
  });
  return (
    <div className={spanClasses}>
      <CircleTickIcon className={iconClasses} />
      {requirement}
    </div>
  );
}
