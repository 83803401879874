import { Label, TextInput } from '@gathercontent/ui';
import { ChangeEvent, useContext, useState } from 'react';
import type { InputHTMLAttributes } from 'react';

import { useHydrated } from '~/helpers/hooks/useHydrated';

import { FormContext } from '~/components/.shared/form/formContext';

import { PasswordFieldValidation } from './PasswordFieldValidation';

interface FormPasswordFieldProps extends InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  name: string;
  enablePasswordChecklist: boolean;
  showValidation?: boolean;
}

export function FormPasswordField({
  label,
  name,
  enablePasswordChecklist,
  showValidation = true,
  ...props
}: FormPasswordFieldProps) {
  const { fieldErrors, defaultValues, validateField } = useContext(FormContext);
  const [showPassword, setShowPassword] = useState(false);
  const [showChecklist, setShowChecklist] = useState(false);
  const [fieldHasValue, setFieldHasValue] = useState(false);
  const hydrated = useHydrated();
  const errors = fieldErrors && fieldErrors[name];
  const errorTextId = `${name}-errors`;

  const onShowPasswordClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    setShowPassword(!showPassword);
  };

  const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    validateField(name, e.target.value);
    setFieldHasValue(!!e?.target?.value);
  };

  return (
    <div className="relative mb-6">
      {label && <Label htmlFor={name}>{label}</Label>}
      {hydrated && (
        <button type="button" onClick={onShowPasswordClick} className="absolute top-0 right-0 link">
          {showPassword ? 'Hide' : 'Show'} password
        </button>
      )}
      <TextInput
        onChange={handleOnChange}
        type={showPassword ? 'text' : 'password'}
        name={name}
        id={name}
        defaultValue={defaultValues && defaultValues[name]}
        aria-describedby={errors ? errorTextId : ''}
        onFocus={() => setShowChecklist(true)}
        onBlur={() => setShowChecklist(false)}
        invalid={Boolean(errors)}
        {...props}
      />
      {showValidation && (
        <PasswordFieldValidation
          errorTextId={errorTextId}
          errors={errors}
          fieldHasValue={fieldHasValue}
          showChecklist={showChecklist}
          enablePasswordChecklist={enablePasswordChecklist}
        />
      )}
    </div>
  );
}

FormPasswordField.defaultProps = {
  enablePasswordChecklist: true,
};
