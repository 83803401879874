import React, { useEffect, useState } from 'react';
import { useTypedActionData as useActionData, useTypedLoaderData as useLoaderData } from 'remix-typedjson';

import type { DataFunctionResult } from '~/data/.types/dataFunctionTypes';

import type { DefaultValues, Values } from '~/components/.shared/form/.types/formTypes';

export function useDefaultValues(
  fetcherData: DataFunctionResult<unknown> | undefined,
): [DefaultValues, React.Dispatch<React.SetStateAction<DefaultValues>>] {
  const actionData = useActionData<DataFunctionResult<{ values: Values }>>();
  const loaderData = useLoaderData<DataFunctionResult<{ values: Values }>>();

  const valuesFromAction = actionData?.data?.values || actionData?.error?.values;
  const valuesFromLoader = loaderData?.data?.values;
  const valuesFromFetcher = fetcherData?.error?.values;

  const [defaultValues, setDefaultValues] = useState<DefaultValues | {}>(valuesFromAction);

  useEffect(() => {
    setDefaultValues(valuesFromAction || valuesFromLoader || valuesFromFetcher);
  }, [valuesFromAction, valuesFromLoader, valuesFromFetcher]);

  return [defaultValues, setDefaultValues];
}
