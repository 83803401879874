import { Label, TextInput } from '@gathercontent/ui';
import { useContext } from 'react';
import type { InputHTMLAttributes } from 'react';

import { FormContext } from '~/components/.shared/form/formContext';
import { ErrorTextList } from '~/components/.ui/errorHandling/ErrorTextList';

export interface FormInputFieldProps extends InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  type: string;
  name: string;
  wrapperClassName?: string;
  inputWrapperClassName?: string;
  validateEvents?: string[];
  inputRef?: React.MutableRefObject<HTMLInputElement>;
}

export function FormInputField({
  label,
  type,
  name,
  wrapperClassName = '',
  inputWrapperClassName = '',
  children,
  validateEvents = ['onBlur'],
  id = '',
  inputRef,
  ...props
}: FormInputFieldProps) {
  const { fieldErrors, defaultValues, validateField } = useContext(FormContext);
  const errors = fieldErrors && fieldErrors[name];
  const errorTextId = `${name}-errors`;

  return (
    <div className={`mb-6 relative ${wrapperClassName}`}>
      {label && <Label htmlFor={name}>{label}</Label>}
      <div className={inputWrapperClassName}>
        <TextInput
          onBlur={(e) => {
            if (validateEvents.includes('onBlur')) {
              validateField(name, e.target.value);
            }
          }}
          onChange={(e) => {
            if (validateEvents.includes('onChange')) {
              validateField(name, e.target.value);
            }
          }}
          type={type}
          name={name}
          id={id || name}
          defaultValue={defaultValues && defaultValues[name]}
          aria-describedby={errors ? errorTextId : ''}
          invalid={Boolean(errors)}
          inputRef={inputRef}
          {...props}
        />
        {children}
      </div>

      {errors && <ErrorTextList messages={errors} id={errorTextId} />}
    </div>
  );
}
