import { Form } from '~/components/.shared/form/Form';
import { FormInputFieldProps } from '~/components/.shared/form/FormInputField';

export function FormHoneypotField(props: Partial<FormInputFieldProps>) {
  return (
    <fieldset style={{ position: 'absolute', left: '-9999px' }} aria-hidden={true}>
      <Form.InputField
        type="text"
        label="Please leave this field blank."
        placeholder="Please leave this field blank."
        name="blankField"
        tabIndex={-1}
        {...props}
      />
    </fieldset>
  );
}
