import { createContext } from 'react';

import type { DataFunctionResult } from '~/data/.types/dataFunctionTypes';

import type { DefaultValues, FieldErrors, Values } from '~/components/.shared/form/.types/formTypes';

export interface FormContextValue {
  fieldErrors: FieldErrors;
  fieldValues: Values;
  validateField: (name: string, value: string) => void;
  defaultValues?: DefaultValues;
  reloadDocument?: boolean;
  fetcherData?: DataFunctionResult<Record<string, unknown>> | undefined;
}

export const FormContext = createContext<FormContextValue>({
  fieldErrors: { '': [''] },
  fieldValues: { '': '' },
  validateField: () => {},
  defaultValues: { '': '' },
  fetcherData: undefined,
});
