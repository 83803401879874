import { Accordion } from '@gathercontent/ui';

import { ExistingTransformationSummary } from './ExistingTransformationSummary';

type ExistingTransformationAccordionProps = {
  value: string;
  displayValue?: string;
  icon: React.ElementType;
  children?: React.ReactNode;
};

export function ExistingTransformationAccordion({
  children,
  value,
  icon,
  displayValue,
}: ExistingTransformationAccordionProps) {
  return (
    <Accordion>
      <Accordion.Summary className="flex w-full border-b-1">
        <ExistingTransformationSummary displayValue={displayValue} value={value} icon={icon} />
      </Accordion.Summary>
      <Accordion.Content>{children}</Accordion.Content>
    </Accordion>
  );
}
