import { Label, TextArea } from '@gathercontent/ui';
import { $generateHtmlFromNodes } from '@lexical/html';
import { $getRoot } from 'lexical';
import type { EditorState, LexicalEditor } from 'lexical';
import { useContext, useState } from 'react';

import { useHydrated } from '~/helpers/hooks/useHydrated';

import { FormContext } from '~/components/.shared/form/formContext';
import { LexicalTextBox } from '~/components/.shared/form/rich-text/LexicalTextBox';
import { ErrorTextList } from '~/components/.ui/errorHandling/ErrorTextList';

interface FormRichTextFieldProps {
  label?: string;
  name: string;
  wrapperClassName?: string;
  errorLocation?: 'top' | 'bottom';
}

export function FormRichTextField({ label, name, wrapperClassName, errorLocation = 'bottom' }: FormRichTextFieldProps) {
  const { fieldErrors, validateField } = useContext(FormContext);
  const errors = fieldErrors && fieldErrors[name];
  const errorTextId = `${name}-errors`;
  const hydrated = useHydrated();
  const [richTextHtml, setRichTextHtml] = useState('');
  const [plainText, setPlainText] = useState('');

  const onRichTextChange = (editorState: EditorState, editor: LexicalEditor) => {
    editor.update(() => {
      const raw = $generateHtmlFromNodes(editor, null);
      const stringifiedEditorState = JSON.stringify(editorState.toJSON());
      const parsedEditorState = editor.parseEditorState(stringifiedEditorState);
      const editorStateTextString = parsedEditorState.read(() => $getRoot().getTextContent());

      if (!plainText && !editorStateTextString) {
        return;
      }
      validateField(name, editorStateTextString);
      setPlainText(editorStateTextString);
      setRichTextHtml(btoa(raw));
    });
  };

  return (
    <div className={`mb-6 ${wrapperClassName}`}>
      <div className="flex items-center justify-between">
        {label && <Label htmlFor={name}>{label}</Label>}
        {errors && errorLocation === 'top' && (
          <ErrorTextList id={errorTextId} messages={errors} className="m-0 pb-2" wrapperClassName="m-0" />
        )}
      </div>
      {hydrated ? (
        <>
          <LexicalTextBox invalid={Boolean(errors)} onChange={onRichTextChange} />
          <input type="hidden" name={name} value={Boolean(plainText) ? richTextHtml : ''} />
        </>
      ) : (
        <>
          <TextArea invalid={Boolean(errors)} id={name} name={name} rows={4} resizable={false} className="h-28" />
        </>
      )}
      {errors && errorLocation === 'bottom' && <ErrorTextList id={errorTextId} messages={errors} />}
    </div>
  );
}
