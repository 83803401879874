import { z } from 'zod';

import {
  passwordLengthRegex,
  passwordLowercaseRegex,
  passwordNumberRegex,
  passwordSpecialRegex,
  passwordUppercaseRegex,
} from '~/helpers/regex/password';

export const passwordErrorMessages = {
  length: 'At least 8 characters',
  lowercase: 'At least 1 lowercase',
  uppercase: 'At least 1 uppercase',
  number: 'At least 1 number',
  special: 'At least 1 special character',
};

export const passwordSchema = z.string().superRefine((val, ctx) => {
  const schema = z.object({
    length: z.string().regex(passwordLengthRegex),
    lowercase: z.string().regex(passwordLowercaseRegex),
    uppercase: z.string().regex(passwordUppercaseRegex),
    number: z.string().regex(passwordNumberRegex),
    special: z.string().regex(passwordSpecialRegex),
  });

  const result = schema.safeParse({
    length: val,
    lowercase: val,
    uppercase: val,
    number: val,
    special: val,
  });

  const allRequirementsAreMet = result.success;
  if (allRequirementsAreMet) {
    return;
  }

  const errors = result.error.flatten();

  if (errors.fieldErrors.length) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message: passwordErrorMessages.length,
    });
  }

  const errorCount = Object.keys(errors.fieldErrors).length;
  if (errorCount > 1) {
    if (errors.fieldErrors.lowercase) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: passwordErrorMessages.lowercase,
      });
    }

    if (errors.fieldErrors.uppercase) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: passwordErrorMessages.uppercase,
      });
    }

    if (errors.fieldErrors.number) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: passwordErrorMessages.number,
      });
    }

    if (errors.fieldErrors.special) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: passwordErrorMessages.special,
      });
    }
  }
});
