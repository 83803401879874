import { InputValidationText } from '@gathercontent/ui';
import type { Error } from 'ts-json-api';

interface ErrorTextListProps {
  messages: string[] | Error;
  id: string;
  wrapperClassName?: string;
  className?: string;
}

export function ErrorTextList({ messages, className, id, wrapperClassName = '' }: ErrorTextListProps) {
  if (!Array.isArray(messages)) return null;
  return (
    <InputValidationText aria-atomic={true} id={id} className={`mt-2 ${wrapperClassName}`}>
      <ul className={`error-text-list ${className}`}>
        {messages.map((message, index) => {
          return (
            <li className="error-text-list-item" key={`error-text-list-${index}`}>
              {message}
            </li>
          );
        })}
      </ul>
    </InputValidationText>
  );
}
